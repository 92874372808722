import { browser } from "$app/environment"
import type { AccountData } from "$bindings/api/AccountData"
import type { AccountPermissionData } from "$bindings/api/AccountPermissionData"
import type { AccountProfileData } from "$bindings/api/AccountProfileData"
import type { AuthResponse } from "$bindings/api/AuthResponse"
import type { LoginData } from "$bindings/api/LoginData"
import type { RegisterData } from "$bindings/api/RegisterData"
import api from "$lib/api"
import { config } from "$lib/api/config"
import { posthog } from "posthog-js"
import { BehaviorSubject, EMPTY, map, Subject, switchMap } from "rxjs"

/* ============================================================================================== */

export class Auth {
    auth$ = new BehaviorSubject<AuthResponse | null>(null)
    signedIn$ = new BehaviorSubject<boolean>(false)
    accountData$ = new BehaviorSubject<AccountData | null>(null)
    accountPermissions$ = new BehaviorSubject<AccountPermissionData | null>(null)

    /**
     * Event: When this user updated its account profile.
     * So that other systems (like group) can be notified.
     */
    accountDataUpdatedEvent$ = new Subject<void>()

    /* ========================================================================================== */

    constructor() {
        if (browser) this.renew()

        // Set token on api
        this.auth$.pipe(map((auth) => auth?.token)).subscribe((token) => {
            if (token) {
                config.headers["authorization"] = `Bearer ${token}`
            } else {
                delete config.headers["authorization"]
            }
        })

        this.auth$
            .pipe(
                switchMap((auth) => {
                    if (auth === null) {
                        // @feat: PostHog
                        posthog.reset()

                        return EMPTY
                    }

                    return api.account.get({ id: auth.account_id }).then((result) => {
                        return { auth, result }
                    })
                }),
            )
            .subscribe(({ auth, result }) => {
                if (result.ok) {
                    const accountData = result.value

                    this.accountData$.next(accountData)

                    // @feat: PostHog
                    posthog.identify(auth.account_id, {
                        email: auth.email,
                        name: accountData.name,
                        username: accountData.username,
                    })
                }
            })

        this.auth$
            .pipe(
                switchMap((auth) => {
                    if (auth === null) return EMPTY
                    return api.account_permission.get_my()
                }),
            )
            .subscribe((result) => {
                if (result.ok) {
                    this.accountPermissions$.next(result.value)
                } else {
                    this.accountPermissions$.next(null)
                }
            })
    }

    private handleReponse(auth_response: AuthResponse) {
        this.auth$.next(auth_response)
        this.signedIn$.next(true)
    }

    /* ================================== API Wrapper functions ================================= */

    async register(data: RegisterData) {
        console.log("[Auth] register()")
        const result = await api.auth.register(data)
        if (result.ok) {
            this.handleReponse(result.value)
        }
        return result
    }

    async login(data: LoginData) {
        console.log("[Auth] login()")
        const result = await api.auth.login(data)
        if (result.ok) {
            this.handleReponse(result.value)
        }
        return result
    }

    async renew() {
        console.log("[Auth] renew()")
        const result = await api.auth.renew()
        if (result.ok && result.value !== null) {
            this.handleReponse(result.value)
        }
        return result
    }

    async logout() {
        console.log("[Auth] logout()")
        const result = await api.auth.logout()
        if (result.ok) {
            this.auth$.next(null)
            this.signedIn$.next(false)
            this.accountData$.next(null)
            this.accountPermissions$.next(null)
        }
        return result
    }

    async updateAccountProfile(accountUpdate: AccountProfileData) {
        console.log("[Auth] updateAccount()")
        const result = await api.account.update_profile(accountUpdate)
        if (result.ok) {
            this.accountData$.next(result.value)
            this.accountDataUpdatedEvent$.next()
        }
        return result
    }
}

/* ============================================================================================== */

export const auth = new Auth()
