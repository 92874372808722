import { browser } from "$app/environment"
import * as env from "$env/static/public"

import type { Config } from "$bindings/tapi/internal"

/* ============================================================================================== */

/** This config is passed to the api client object, and can be updated at any time. */
export const config: Config = {
    baseUrl: browser ? env.PUBLIC_API_URL : env.PUBLIC_API_URL_SERVER,
    headers: {},
}
